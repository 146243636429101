
import React, { useState, CSSProperties } from 'react';
import PacmanLoader from "react-spinners/PacmanLoader";


export default function Spinner(props){

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#229CFB");

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
      <>
        {props.loading &&
          <div className={"loader-wrapper"}>
            <PacmanLoader color={color} loading={true} cssOverride={override} size={150}/>
            {/*<div className={"loader"}>Loading...</div>*/}
          </div>
        }
      </>
  )

}
