import { createContext } from "react";

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import InitialScreen from '../screens/InitialScreen'
import HomeScreen from '../screens/HomeScreen'
import StoryScreen from '../screens/story/StoryScreen'
import FinalScreen from '../screens/FinalScreen'

const RootNavigator = () => {
  return (
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="initial" element={<InitialScreen />} />
            <Route path="/story/:id" element={<StoryScreen />} />
            <Route path="/final" element={<FinalScreen />} />
          </Routes>
        </BrowserRouter>
  )
};


export default RootNavigator;
