import React, { useState, useEffect, useRef, useContext } from 'react';
import MainLayout from "../../components/MainLayout";

import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import $ from 'jquery'
import {Route, Link, Routes, useParams} from 'react-router-dom';

import Spinner from '../../components/Spinner'

import { useNavigate } from "react-router-dom";
import Chars from "../../const/characters";
import Bubbles from "../../const/bubbles";
import storyObj from '../../const/story'
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import queryString from 'query-string';

import { StoreContext } from '../../App';

console.log ('-->window.innerHeight()', window.innerHeight );


const StoryScreen = (props) => {

  const { editor, onReady } = useFabricJSEditor({
    selection: true,
    selectionBorderColor: 'green',
    'dir': 'rtl',
  });

  const [state, setState] = useState({
    story: null,
    storyNum: null,
    storyBgIndex: 0,
    busy: false,
    arrowBackDisabled: true,
    isLastSeen: false,
    initialize: false
  });

  const params = useParams();

  const myStores = useContext(StoreContext);
  const navigate = useNavigate();

  const renderIcon = (ctx, left, top, styleOverride, fabricObject) => {
    const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
    const img = document.createElement('img');
    img.src = deleteIcon;
    fabric.Object.prototype.cornerColor = '#131313';
    fabric.Object.prototype.transparentCorners = false;

    const size = 20;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size/2, -size/2, size, size);
    ctx.restore();
  };

  const  deleteObject = (eventData, target) => {
    const canvas = target.target.canvas;
    console.log ('-->canvas', canvas );
    editor.canvas.remove(target.target);
    editor.canvas.requestRenderAll();
  };

  const setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    if (!dataUrl) { return true; }
    //const canvas = editor.canvas;
    let img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      fabric.Image.fromURL(dataUrl, function(img) {
        img.scaleToWidth(1370);
        img.scaleToHeight(590);
        editor?.canvas?.setBackgroundImage(img);
        editor?.canvas?.requestRenderAll();
      });
    };

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 16,
      cursorStyle: 'pointer',
      mouseUpHandler: deleteObject,
      render: renderIcon,
      cornerSize: 24
    });

    setTimeout(()=>{
      editor?.canvas?.renderAll();
    }, 1500);

    img.src = dataUrl;
  };

  const imgDeletefn = () => {
    const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
    const imgDelete = document.createElement('img');
    imgDelete.src = deleteIcon;
    fabric.Object.prototype.cornerColor = '#131313';
    fabric.Object.prototype.transparentCorners = false;

    return imgDelete;
  };

  const  onObjectSelected = () => {

  };

  const handleDragStart = (e) => {
    var images = document.querySelectorAll('#images img');
    [].forEach.call(images, function(img){
      img.classList.remove('img_dragging');
    });
    this.classList.add('img_dragging');
  };

  const onDoubleClickHandler = (e) => {
    var data = $(e.target).attr('id');
    var imag = document.getElementById(data);
    var img = new fabric.Image(imag, {
      //width: 100,
      //height: 100,
      left: 0 ,
      top: 0
      //left: 0,
      //top: 10
    });
    img.scaleToWidth(200);
    img.scaleToHeight(300);

    editor.canvas.add(img);
    editor?.canvas.setActiveObject(img);
    editor.canvas.centerObject(img);
  };

  const dragOvertHandler = (e) => {
    console.log('-->e.target', e.target);

    e.preventDefault();

    console.log('-->e.layerX', e);

    var data = $(e.target).attr('id');
    var imag = document.getElementById(data);

    const clintX = e.clientX - 880;
    const clientY = e.clientY - 200;

    var img = new fabric.Image(imag, {
      //width: 100,
      //height: 100,
      left: clintX ,
      top: clientY
      //left: 0,
      //top: 10
    });
    img.scaleToWidth(200);
    img.scaleToHeight(300);


    editor.canvas.add(img);
    editor?.canvas.setActiveObject(img);
  };

  const onBubbleDragOver = (e) => {
    e.preventDefault();
    var data = $(e.target).attr('id');
    var imag = document.getElementById(data);

    const clintX = e.clientX - 880;
    const clientY = e.clientY - 200;

    /*var itext = new fabric.IText('السلام عليكم يا اصدقائي', {
      left:  clintX + 250,
      top:  clientY + 100,
      width: 350,
      height: 300,
      fontSize: 26,
      fill: '#000000',
      direction: 'ltr',
      selectionDirection: 'ltr',
      textAlign: 'right',
      originX: 'right',
      fontFamily: 'Arial'
    });*/

    var img = new fabric.Image(imag, {
      //width: 100,
      //height: 100,
      left: clintX ,
      top: clientY,
      //itextRef: itext,
      transparent: true
    });
    img.scaleToWidth(120);
    img.scaleToHeight(120);

    editor.canvas.add(img);
    //editor?.canvas.add(itext);
    editor?.canvas.setActiveObject(img);
  };

  const onBubbleClickHandler = (e) => {
    e.preventDefault();
    var data = $(e.target).attr('id');
    var imag = document.getElementById(data);

    const clintX = 0;
    const clientY = 0;

    var img = new fabric.Image(imag, {
      //width: 100,
      //height: 100,
      left: clintX ,
      top: clientY,
      //itextRef: itext,
      transparent: true
    });
    img.scaleToWidth(120);
    img.scaleToHeight(120);

    editor.canvas.add(img);
    //editor?.canvas.add(itext);
    editor?.canvas.setActiveObject(img);
    editor.canvas.centerObject(img);
  };

  const addText = () => {
    var itext = new fabric.IText('...', {
      left: 250,
      top: 100,
      width: 350,
      height: 300,
      fontSize: 30,
      fill: '#000000',
      direction: 'ltr',
      textAlign: 'center',
      originX: 'center',
      moveCursor: 'pointer',
      fontFamily: 'AraAsmaaBeltajie-Regular'
    });

    //editor?.canvas?.setAttribute('dir', 'rtl');
    //editor.canvas.bringToFront(itext);
    editor.canvas.add(itext);
    editor.canvas.centerObject(itext);
    //editor.canvas.bringToFront(itext);


    editor?.canvas.setActiveObject(itext)
  }

  const handleOnClick = () => {

  }

  const goToNextScreen = () => {
    setState(state=>({...state, busy: true}));
    const pngUrl = editor?.canvas.toDataURL();
    let story = myStores.storyStore.selectedStory;
    let currentIndex = myStores.storyStore.storyBgIndex;
    let nextBgIndex = currentIndex + 1;

    //save current data
    let canvasJson = editor?.canvas.toJSON();
    myStores.storyStore.historyCanvas.set(currentIndex, canvasJson);
    myStores.storyStore.finalImages.set(currentIndex, pngUrl);

    if(story[nextBgIndex] !== undefined)
    {
      editor?.canvas.clear();

      let nextCanvas = myStores.storyStore.historyCanvas.get(nextBgIndex);
      if(nextCanvas)
        editor?.canvas.loadFromJSON(nextCanvas);
      else
        setBackgroundFromDataUrl('.' + story[ nextBgIndex] );

      myStores.storyStore.storyBgIndex = nextBgIndex;

      //state.isLastSeen
      if(nextBgIndex === myStores.storyStore.selectedStory.length - 1 )
        setState(state=>({...state, isLastSeen: true}))
    }
    else
    {
      console.log ('-->go To Final Seen' );
      editor?.canvas.clear();
      navigate("/final");
      setState(state=>({...state, busy: false}));
    }

    setTimeout(()=>{
      editor?.canvas.renderAll();
      console.log ('-->go Next Done' );
      setState(state=>({...state, busy: false, arrowBackDisabled: false}));
    }, 500);
  };

  const goToBackScreen = () => {
    setState(state=>({...state, busy: true, isLastSeen: false}));

    const pngUrl = editor?.canvas.toDataURL();
    let story = myStores.storyStore.selectedStory;
    let currentIndex = myStores.storyStore.storyBgIndex;
    let prevBgIndex = currentIndex - 1;

    if(currentIndex === 0)
    {
      setState(state=>({...state, busy: false, arrowBackDisabled: true}));
      return;
    }

    if(prevBgIndex === 0)
      setState(state=>({...state, arrowBackDisabled: true}));

    //save current data
    let canvasJson = editor?.canvas.toJSON();
    myStores.storyStore.historyCanvas.set(currentIndex, canvasJson);
    myStores.storyStore.finalImages.set(currentIndex, pngUrl);

    //go back
    editor?.canvas.clear();
    let prevCanvas = myStores.storyStore.historyCanvas.get(prevBgIndex);
    editor?.canvas.loadFromJSON(prevCanvas);
    myStores.storyStore.storyBgIndex = prevBgIndex;

    setTimeout(()=>{
      editor?.canvas.renderAll();
      console.log ('-->Back Done' );
       setState(state=>({...state, busy: false}));
    }, 500);
  };

  const onBackPress = () => {
    console.log ('-->onBackPress' );
  };

  const initialize = async () => {
    if(state.initialize)
      return;

    setState(state=>({...state, busy: true}));

    setTimeout(()=>{
      let story = null;
      let storyNum = null;

      story = myStores.storyStore.selectedStory;
      storyNum = myStores.storyStore.selectedStoryNum;
      myStores.storyStore.storyBgIndex = state.storyBgIndex;

      const search = window.location.search;
      const paramsx = new URLSearchParams(search);
      const load = paramsx.get('load');


      if(story === null)
      {
        if(params.id === '1')
        {
          story = storyObj.story1;
          storyNum = 1;
        }
        else
        {
          story = storyObj.story2;
          storyNum = 2
        }
      }

      myStores.storyStore.selectedStory = story;
      myStores.storyStore.selectedStoryNum = storyNum;

      setState(state=>({...state, story: story, storyNum: storyNum}));

      if(parseInt(load) === 1)
      {
        let prevCanvas = myStores.storyStore.historyCanvas.get(0);
        editor?.canvas.loadFromJSON(prevCanvas);
      } else {
        setBackgroundFromDataUrl('.' + story[state.storyBgIndex] );
      }


      editor?.canvas.on('selection:created', onObjectSelected);
      setState(state=>({...state, initialize: true}))
    }, 50);

    setTimeout(()=>{
      setState(state=>({...state, busy: false}));
    }, 100)
  };

  useEffect(() => {
    initialize();
  });

  return (
      <MainLayout onBackPress={onBackPress} back={true}>

              <div className="flex flex-row  bg-red-100">
                <div className="basis-3/6 " >
                  <div className="card bg-base-200 shadow-xl bg-gray-100">
                    <div className="card-body">
                      <h2 className="card-title" dir="rtl"> اسحب شخصية
                        Drag a character </h2>
                      <div className="flex flex-row flex-wrap" style={{marginLeft: 30 ,height: '775px', overflow: 'scroll' }}>
                        {Chars.chars.map((row, indx) => (
                            <div className="basis-3/3 w-6/6 " key={indx}>
                              <img onClick={handleOnClick} style={{ cursor: 'move', width: 100, height: 240, marginTop: 20, marginRight: 45 }}
                                   className="chart" id={'chart_' + indx}  src={row}
                                   draggable="true"
                                   onDragEnd={dragOvertHandler}
                                   onTouchEnd={onDoubleClickHandler}
                                   onDoubleClick={onDoubleClickHandler}
                              />
                            </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basis-5/6 bg-gray-400 ">
                  <div>
                    <div className="h-12 ">
                      <div className=" flex flex-row h-40 bg-gray-300 " dir="rtl">
                        <div className="p-5">
                          <img width={'25'} height={'25'} src="../assets/testImages/characters/1.png" />
                        </div>
                        <div className="w-full mt-4 ml-10" id="storyHint">
                          <div className="alert shadow-lg ">
                            <div className="w-full ">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info flex-shrink-0 w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                              </svg>
                              <div className="w-full ">
                                <div className="flex flex-row  ">
                                  <div className="basis-4/12 mt-3">
                                    <b style={{fontSize: 15}}>تخيل حوار شييق بين الشخصيات واخبرنا عن قصتك لهذا المشهد!</b>
                                  </div>
                                  <div className="basis-4/12 flex justify-center">
                                    <button className="btn btn-info mr-5" onClick={addText}>اضغط هنا لإضافة النص
                                      Click here to add text</button>
                                  </div>
                                  <div className="basis-4/12 flex justify-end">
                                    <button disabled={state.arrowBackDisabled} className="btn btn-success mr-5" onClick={goToBackScreen}>
                                      <FaArrowRight style={{marginLeft: 10}} />
                                      السابق
                                    </button>
                                    <button className="btn btn-success mr-5" onClick={goToNextScreen}>
                                      {state.isLastSeen ? 'انهاء' : 'التالي' }

                                      <FaArrowLeft style={{marginRight: 10}} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-[70px] ml-10 mb-3">
                      <FabricJSCanvas id="canvas" className="sample-canvas" onReady={onReady} dir="rtl"/>
                    </div>
                    <div>
                      <div className="card bg-base-100 shadow-xl ml-10 mr-10 ">

                        <div className="card-body" dir="rtl">
                          <h2 className="card-title" style={{ marginTop: - 20}}> اسحب فقاعه الحوار </h2>
                          <div className="flex flex-column flex-wrap h-20" style={{ marginTop: 5}}>
                            {Bubbles.bubbles.map((row, indx) => (
                                <div className="basis-1/12 m-1 " key={indx}>
                                  <img onClick={handleOnClick} width={'100'} height={'100'} style={{ cursor: 'move' }}
                                       className="bubbleX" id={'bubbleX_' + indx} src={row} width={100} height={200} draggable="true"
                                      onDragEnd={onBubbleDragOver}
                                       onTouchEnd={onBubbleClickHandler}
                                       onDoubleClick={onBubbleClickHandler}
                                  />
                                </div>
                            ))}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

        <Spinner loading={state.busy} />
      </MainLayout>
  )
};

export default StoryScreen;
