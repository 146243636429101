import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from '../App';
import storyObj from '../const/story'

const HomeScreen = (props) => {
  const navigate = useNavigate();
  const myStores = useContext(StoreContext);

  const goToStory1 = () => {
    myStores.storyStore.selectedStory = storyObj.story1;
    myStores.storyStore.selectedStoryNum = 1;
    navigate("/story/1");
  };

  const goToStory2 = () => {
    myStores.storyStore.selectedStory = storyObj.story2;
    myStores.storyStore.selectedStoryNum = 2;
    navigate("/story/2");
  };

  return (
      <MainLayout>

        <div className="flex flex-row " style={{height: 855,

        }}>

          <div className={"basis-3/6 bg-green-100"} style={{
            borderRightWidth: 5,
            borderRightColor: 'white',
            //backgroundColor: '#4F44B6',
            backgroundImage: `url("${storyObj.story1[0]}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            textAlign: 'center',
            //opacity: 0.6
            //marginTop: 200
          }}>
            <button className="btn btn-wide" style={{
              marginTop: 730
            }} onClick={ goToStory1 }>
              Story 1 / قصة 1
            </button>
          </div>

          <div className={'basis-3/6'} style={{
            //flexDirection: 'row-end'
            borderLeftWidth: 5,
            borderLeftColor: 'white',
            backgroundColor: '#4F44B6',
            textAlign: 'center',
            backgroundImage: `url("${storyObj.story2[0]}")`,
            backgroundRepeat: 'no-repeat',
            //backgroundSize: '100% 50%',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            //opacity: 0.8
          }}>


            <div className="" style={{
              marginTop: 730,
              opacity: 2
            }}>

              <button className="btn btn-wide" onClick={ goToStory2 }>Story 2 / قصة 2</button>

            </div>

          </div>

        </div>


       {/*<Link to={'story'} >story</Link>*/}

     </MainLayout>
  )
};


export default HomeScreen;
