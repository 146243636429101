
const HomeScreen = () => {
  return (
      <div>
        Initial
      </div>
  )
}


export default HomeScreen;
