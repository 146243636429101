import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from '../App';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';

import {
  Typography,
  AppBar,
  Toolbar,
  TextField,
  TextareaAutosize,
  Button,
  Box
} from "@material-ui/core";

import * as yup from 'yup';
import { Formik } from 'formik';

import {toast } from 'react-toastify';

import storyObj from '../const/story'
import $ from "jquery";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";

const FinalScreen = (props) => {
  const { editor, onReady } = useFabricJSEditor({
    selection: true,
    selectionBorderColor: 'green',
  });

  const [state, setState] = useState({
    busy: false,
    initialize: false,
    codeErrorMsg: '',
    validationSchema: {
      storyName: yup.string().required(),
      childName: yup.string().required(),
      email: yup.string().email().required(),
    },
    formData: {
      storyName: '',
      childName: '',
      email: '',
    },
  });

  const navigate = useNavigate();
  const myStores = useContext(StoreContext);

  const notify = () => toast("جاري ارسال ملف القصه للبريد");

  const setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    if (!dataUrl) { return true; }
    //const canvas = editor.canvas;
    let img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      // maxWidth // Max width of the canvas
      // maxHeight //Max height of canvas
      let maxWidth = 1050;
      let maxHeight = 800;
      let imgWidth = img.width;
      let imgHeight = img.height;
      let widthAspectRatio = maxWidth / imgWidth;
      let heightAspectRatio = maxHeight / imgHeight;
      let finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);
      let finalHeight = imgHeight * finalAspectRatio;
      let finalWidth = imgWidth * finalAspectRatio;

      let imgTop = 0;
      if (maxHeight > finalHeight) {
        imgTop = (Math.round(maxHeight) - Math.round(finalHeight)) / 2;
      }
      let imgLeft = 0;
      if (maxWidth > finalWidth) {
        imgLeft = (Math.round(maxWidth) - Math.round(finalWidth)) / 2;
      }
      let nsgImage = new fabric.Image(img).scale(finalAspectRatio);

      //finalWidth = 1050;
      //finalHeight = 800;

      nsgImage.set({ left: 0, top: 0 });
      editor?.canvas?.setBackgroundImage(nsgImage, () => editor?.canvas?.renderAll());
      $(".final-canvas").attr('style',`background-color:#ffffff; width:${finalWidth }px;height:${finalHeight}px;overflow:hidden;margin-top: -7px;`);
      $('#storyHint').attr('style', `width:${finalWidth}`);
    };

    setTimeout(()=>{
      editor?.canvas?.renderAll();
    }, 1500);

    img.src = dataUrl;
  };

  const changeCanvaTxt = (txt, id) => {
    editor?.canvas.getObjects().forEach(function(o) {
      if (o.type === 'text' && o.xid === id) {
        o.text = txt;
        editor?.canvas.renderAll();
        editor?.canvas.setActiveObject(o);
      }
    });
  };

  const onChangeStoryName = (e) => {
    changeCanvaTxt(e.target.value, 'storyTitle');
    let formData = state.formData;
    formData['storyName'] = e.target.value;
    setState(state=>({...state, formData: formData}));
  };

  const onChangeChildName = (e) => {
    changeCanvaTxt(e.target.value, 'childName')
    let formData = state.formData;
    formData['childName'] = e.target.value;
    setState(state=>({...state, formData: formData}));
  };

  const onChangeEmail = (e) => {
    let formData = state.formData;
    formData['email'] = e.target.value;
    setState(state=>({...state, formData: formData}));
  };

  const onSave = () => {

    if(state.formData['email'] === '')
      return;

    const pngUrl = editor?.canvas.toDataURL();
    let canvasJson = editor?.canvas.toJSON();

    let finalImages = myStores.storyStore.finalImages;

    let images = [];
    images.push(pngUrl);
    finalImages.forEach((val, key) => {
      images.push(val);
    });

    notify();
    myStores.storyStore.sendStoryToMail({
      images: images,
      email: state.formData['email']
    }).then(res=>{
      console.log ('-->res', res );
    }).finally(()=>{

    });

    myStores.storyStore.finalImages = new Map();
    myStores.storyStore.selectedStory = null;
    myStores.storyStore.selectedStoryNum = null;
    myStores.storyStore.storyBgIndex = null;
    myStores.storyStore.historyCanvas = new Map();
    navigate("/");
  };

  const intialStory1 = () => {
    setBackgroundFromDataUrl('./assets/stories/story1/cover.jpg');

    var itext = new fabric.IText('', {
      "left": 539,
      "top": 157,
      width: 350,
      height: 300,
      fontSize: 70,
      fill: '#000000',
      direction: 'ltr',
      textAlign: 'center',
      originX: 'center',
      fontFamily: 'AraAsmaaBeltajie-Regular',
      editable: false,
      type: 'text',
      xid: 'storyTitle'
    });
    editor?.canvas?.add(itext);

    var itext = new fabric.IText('تأليف و كتابه', {
      "left": 537,
      "top": 555,
      width: 350,
      height: 300,
      fontSize: 30,
      fill: '#000000',
      direction: 'ltr',
      textAlign: 'center',
      originX: 'center',
      fontFamily: 'AraAsmaaBeltajie-Regular',
      editable: false,
    });

    editor?.canvas?.add(itext);

    var itext = new fabric.IText('', {
      "left": 547,
      "top": 607,
      width: 350,
      height: 300,
      fontSize: 30,
      fill: '#000000',
      direction: 'ltr',
      textAlign: 'center',
      originX: 'center',
      editable: false,
      fontFamily: 'AraAsmaaBeltajie-Regular',
      type: 'text',
      xid: 'childName'
    });
    editor?.canvas?.add(itext);
  };

  const intialStory2 = () => {
    setBackgroundFromDataUrl('./assets/stories/story2/cover.jpg');

    var itext = new fabric.IText('', {
      "left": 326.45,
      "top": 201.28,
      angle: 355,
      width: 350,
      height: 300,
      fontSize: 50,
      fill: '#000000',
      direction: 'ltr',
      textAlign: 'center',
      originX: 'center',
      fontFamily: 'AraAsmaaBeltajie-Regular',
      editable: false,
      type: 'text',
      xid: 'storyTitle'
    });
    editor?.canvas?.add(itext);

    var itext = new fabric.IText('تأليف و كتابه', {
      "left": 345.1,
      "top": 300.17,
      angle: 355,
      width: 350,
      height: 300,
      fontSize: 30,
      fill: '#000000',
      direction: 'ltr',
      textAlign: 'center',
      originX: 'center',
      fontFamily: 'AraAsmaaBeltajie-Regular',
      editable: false,
    });

    editor?.canvas?.add(itext);

    var itext = new fabric.IText('', {
      "left": 357.1,
      "top": 342.17,
      angle: 355,
      width: 350,
      height: 300,
      fontSize: 30,
      fill: '#000000',
      direction: 'ltr',
      textAlign: 'center',
      originX: 'center',
      editable: false,
      fontFamily: 'AraAsmaaBeltajie-Regular',
      type: 'text',
      xid: 'childName'
    });
    editor?.canvas?.add(itext);
  };

  const initialize = () => {
    if(state.initialize)
      return;

    setTimeout(()=>{

      if(myStores.storyStore.selectedStoryNum === 1)
        intialStory1();
      else
        intialStory2();

      setState(state=>({...state, initialize: true}))
    }, 500);

  };

  const goToBackStory = () => {
    navigate(`/story/${myStores.storyStore.selectedStoryNum}?load=1`);
  };

  useEffect(() => {
    initialize();
  });

  return (
      <MainLayout>

        <div className="flex flex-row " style={{height: 1000,

        }}>

          <div className={"basis-2/6 bg-green-100"} style={{
            borderRightWidth: 5,
            borderRightColor: 'white',
            //textAlign: 'center',
          }}>

            <Formik
                enableReinitialize
                initialValues={state.formData}
                validationSchema={yup.object().shape(state.validationSchema)}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                  try {
                    await onSave(values, setFieldError);
                  } catch (e) {
                    if (e.errors.length > 0) {
                      e.errors.map((err, i) => {
                        setFieldError(Object.keys(err)[0], err[Object.keys(err)[0]]);
                      });
                    }
                  }
                }}>
              {({ isValid, values, handleChange, touched, errors, handleSubmit, handleBlur, isSubmitting, setFieldValue }) => (
                  <div dir={'rtl'} style={{
                    marginTop: 40,
                    //width: "500px", margin: "25px", textAlign: 'right',
                    //backgroundColor: 'red'
                  }}>


                    <div style={{
                      width: 'auto',
                      //height: 60,
                      marginTop: 20,
                      textAlign: 'center',
                      alignSelf: 'center',
                      fontWeight: 'bold'
                    }}>أرسال القصه لبريدك الالكتروني</div>
                    <form onSubmit={handleSubmit} style={{

                    }}>

                      <div dir={'rtl'}>
                        <textarea
                            //dir={'rtl'}
                            style={{
                              width: "500px",
                              margin: "25px",
                              textAlign: 'right',
                              backgroundColor: '#DBFCE7',
                              borderStyle: 'solid',
                              borderWidth: 2,
                              borderColor: 'rgba(0, 0, 0, 0.23)',
                              borderRadius: 'inherit'
                            }}
                            type="text"
                            placeholder="عنوان القصه"
                            variant="outlined"
                            rows={2}
                            autoFocus={true}
                            onChange={(e)=>{
                              setFieldValue("storyName", e.target.value);
                              handleChange('storyName');
                              onChangeStoryName(e)
                            }}
                            //onChange={onChangeStoryName}
                        />
                        {errors.storyName &&
                          <div style={{
                            color: 'red',
                            marginTop: -25,
                            marginRight: 30
                          }}>حقل مطلوب</div>
                        }
                      </div>


                      <TextField
                          style={{ width: "500px", margin: "25px", textAlign: 'right' }}
                          type="text"
                          placeholder="اسم الطفل"
                          variant="outlined"
                          onChange={(e)=>{
                            setFieldValue("childName", e.target.value);
                            handleChange('childName');
                            onChangeChildName(e)
                          }}
                      />
                      {errors.childName &&
                        <div style={{
                          color: 'red',
                          marginTop: -25,
                          marginRight: 30
                        }}>حقل مطلوب</div>
                      }

                      <br />
                      <TextField
                          style={{ width: "500px", margin: "25px", textAlign: 'right' }}
                          type="text"
                          placeholder="البريد الالكتروني"
                          variant="outlined"
                          onChange={(e)=>{
                            setFieldValue("email", e.target.value);
                            handleChange('storyName');
                            onChangeEmail(e)
                          }}
                      />
                      {errors.email &&
                        <div style={{
                          color: 'red',
                          marginTop: -25,
                          marginRight: 30
                        }}>حقل مطلوب</div>
                      }

                      {/*<input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                      />
                      {errors.password && touched.password && errors.password}*/}

                      <div style={{
                        //backgroundColor: 'red',
                        width: 'auto',
                        //height: 60,
                        marginTop: 20,
                        textAlign: 'center',
                        alignSelf: 'center'
                      }}>

                        <button className="btn btn-wide"
                                disabled={ !isValid }
                                style={{
                                  textAlign: 'center',
                                  alignSelf: 'center'
                                }}
                            onClick={ onSave}
                        >
                          ارســــــــال
                        </button>
                      </div>



                    </form>

                  </div>
              )}
            </Formik>


          </div>

          <div className={'basis-4/6'} style={{
            borderLeftWidth: 5,
            borderLeftColor: 'white',
            backgroundColor: '#4F44B6',
            textAlign: 'center',
          }}>

            {/*<button  className="btn btn-success mr-5" dir={'rtl'} onClick={goToBackStory}>
              <FaArrowRight style={{marginLeft: 10}} />
              العوده للقصه السابقه
            </button>*/}

            <div className="mt-[50px] ml-10 mb-3" >
              <FabricJSCanvas id="final-canvas" className="final-canvas" onReady={onReady}/>
            </div>

          </div>

        </div>


        {/*<Link to={'story'} >story</Link>*/}

      </MainLayout>
  )
};


export default FinalScreen;
