module.exports = {
    "story1BG": "",
    "story2BG": "",
    "story1": [
      './assets/stories/story1/1.jpg',
      './assets/stories/story1/2.jpg',
      './assets/stories/story1/3.jpg',
      './assets/stories/story1/5.jpg',
      //'./assets/stories/story1/6.jpg',
    ],
  "story2": [
    './assets/stories/story2/1.jpg',
    './assets/stories/story2/2.jpg',
    './assets/stories/story2/3.jpg',
    './assets/stories/story2/4.jpg',
    //'./assets/stories/story2/5.jpg',
  ]
};
