

class StoryStore {

  constructor(){
    this.selectedStory = null;
    this.selectedStoryNum = null;
    this.storyBgIndex = null;
    this.historyCanvas = new Map();
    this.finalImages = new Map();
  }

  sendStoryToMail = async (params) => {
    try {
      const url = 'https://admin.ribf.app/Api/ar/imageToPdfToMail' ;
      const request = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( params )
      };

      const response = await fetch(url, request);
      const json = await response.json();

      console.log ('-->fetch post', url, request, json );
      if(json.status !== 'ok')
      {
        console.log ('-->notok1', json  );
        return Promise.reject(json);
      }
      return Promise.resolve(json);
    } catch (error) {
      console.log('catchError',error);
      return Promise.reject(error)
    }
  };

}


export default StoryStore;
