module.exports = {
    chars: [
      '../assets/stories/characters/story/1.png',
      '../assets/stories/characters/story/2.png',
      '../assets/stories/characters/story/3.png',
      '../assets/stories/characters/story/4.png',
      '../assets/stories/characters/story/5.png',
      '../assets/stories/characters/story/6.png',
      '../assets/stories/characters/story/7.png',
      '../assets/stories/characters/story/8.png',
      '../assets/stories/characters/story/9.png',
      '../assets/stories/characters/story/10.png',
      '../assets/stories/characters/story/11.png',
      '../assets/stories/characters/story/12.png',
      '../assets/stories/characters/story/14.png',
      '../assets/stories/characters/story/15.png',
      '../assets/stories/characters/1.png',
      '../assets/stories/characters/3.png',
      '../assets/stories/characters/4.png',
      '../assets/stories/characters/5.png',
      '../assets/stories/characters/6.png',
      '../assets/stories/characters/7.png',
      '../assets/stories/characters/8.png',
      '../assets/stories/characters/9.png',
      '../assets/stories/characters/10.png',
      '../assets/stories/characters/11.png',
      '../assets/stories/characters/12.png',
      '../assets/stories/characters/13.png',
    ]


}
