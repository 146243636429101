import * as React from 'react';
import { useContext } from "react";
import { StoreContext } from "../App";
import { useNavigate } from "react-router-dom";
//import MainLayout from "../screens/story/StoryScreen";


const drawerWidth = 240;

export default function MainLayout(props){
  //const { window } = props;
  const navigate = useNavigate();
  const myStores = useContext(StoreContext);

  const onReset = () => {
    myStores.storyStore.finalImages = new Map();
    myStores.storyStore.selectedStory = null;
    myStores.storyStore.selectedStoryNum = null;
    myStores.storyStore.storyBgIndex = null;
    myStores.storyStore.historyCanvas = new Map();
    navigate("/");
  };

  const onFullScreen = () => {

  };

  return (
      <div className="h-scree bg-no-repeat bg-cover bg-center bg-[url('/assets/testImages/bg11.jpeg')]">

        <div className="" style={{ paddingTop: 30, paddingLeft: 50, paddingRight: 50 }}>

          <div className={' h-[' + window.innerHeight + ']'}>
            <div className=" flex-column  ">
              <div className="w-full">

                <div className="navbar  bg-[#229BFB] text-neutral-content">
                  <div className="flex-1">
                    <a className="btn btn-ghost normal-case text-xl">Max Story</a>

                    {/*{props.back &&
                      <a onClick={props.onBackPress}>
                        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                             viewBox="0 0 24 24">
                          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                        </svg>
                      </a>
                    }*/}

                  </div>
                  <div className="flex-none" style={{direction: 'rtl'}}>

                    <ul className="menu menu-horizontal p-0" >
                      <li tabIndex="0">
                        <a>

                          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                               viewBox="0 0 24 24">
                            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                          </svg>
                        </a>
                        <ul className="p-2 bg-base-100">
                          <li className="">
                            <button className="btn" style={{marginBottom: 10}} onClick={onReset}>Rest</button>
                            {/*<button className="btn" onClick={onFullScreen}>Full Screen</button>*/}
                          </li>
                          {/*<li><a>Submenu 2</a></li>*/}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="w-full bg-red-100">

                {props.children}

              </div>

            </div>
          </div>

        </div>

      </div>

  );
}
