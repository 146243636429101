module.exports = {
  'bubbles': [
    '../assets/stories/bubbles/B1.png',
    '../assets/stories/bubbles/B2.png',
    '../assets/stories/bubbles/B3.png',
    '../assets/stories/bubbles/B4.png',
    '../assets/stories/bubbles/B5.png',
    '../assets/stories/bubbles/B6.png',
    //'../assets/stories/bubbles/7.png',
    '../assets/stories/bubbles/8.png',
    '../assets/stories/bubbles/10.png',
    '../assets/stories/bubbles/11.png',
    '../assets/stories/bubbles/12.png',
    //'../assets/stories/bubbles/13.png',
    //'../assets/stories/bubbles/14.png',
  ]
};
